/*# Color Schema
Prussian Blue : #0B3C5D
Sky Blue : #328CC1
Gold Leaf : #D9B310
Ivory Black : #1D2731
*/

.background-header {
  background-color: #0B3C5D;
  width: 100%;
  margin: 0;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  height: 2.4rem;
  max-width: 1080px;
  margin: auto;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  flex-basis: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

nav a {
  text-decoration: none;
  color: inherit;
}

/* TODO : For now keep it plain, style will come later */
/* nav a:link, a:hover, a:visited, a:active {
  color: black;
} */
