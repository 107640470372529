.education {
  font-size: 1rem;
}

.education li {
  list-style: none;
}

.education-form {
  display: flex;
  flex-direction: column;
}

.education-form input {
  margin: 0.5rem;
  min-height: 2rem;
}

.admin-education-form-select {
  background-color: inherit;
  font-size: 1.2rem;
  margin: 0.5rem;
  min-height: 2rem;
}

.admin-education-tag-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 20rem;
  padding: 0;
}

.admin-education-tag-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 2rem;
  margin: 0.1rem;
  border: 1px solid black;
}
