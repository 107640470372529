@import url('https://fonts.googleapis.com/css?family=Arapey');

.admin {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  gap: 2rem;
  height: 100%;

  font-family: "Arapey", Tahoma, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: calc(10px + 2vmin);
}

.admin .admin-left {
  flex: 0 1 auto;
  min-width: 20rem;
  background-color: #282c34;
}

.admin .admin-left ul {
  list-style-type: none;
}

.admin .admin-left li {
  margin-left: 1rem;
  margin-top: 1rem;
}

.admin .admin-left a {
  color: white;
  text-decoration: none;
}

.admin .admin-left a:hover {
  color: #D9B310;
}

.admin .admin-right {
  flex: 1 1 auto;
  min-height: 100vh;
}

.admin .admin-footer {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}
