.work-experience {
  font-size: 1rem;
}

.work-experience li {
  list-style: none;
}

.work-experience-form {
  display: flex;
  flex-direction: column;
}

.work-experience-form input {
  margin: 0.5rem;
  min-height: 2rem;
}
.admin-work-experience-tag-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 20rem;
  padding: 0;
}

.admin-work-experience-tag-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 2rem;
  margin: 0.1rem;
  border: 1px solid black;
}
