.footer-background {
  background-color: #0B3C5D;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer {
  margin: auto;
  max-width: 1080px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-columns {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-columns ul {
  padding: 0;
}

.footer-columns li {
  list-style: none;
}

.footer-columns a {
  color: inherit;
  text-decoration: none;
}

.footer-columns a:visited {
  color: #D9B310;
  text-decoration: none;
}

.footer-message {
  padding: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}
