#certifications-background {
  background-color: #0B3C5D;
}

#certifications {
  margin: auto;
  max-width: 1080px;
  color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

#certifications a {
  color: #D9B310;
}

.certifications-show-more {
  display: flex;
  justify-content: center;
  color: white;
}

.certifications-show-more .item {
  color: white;
  border: 0.1rem solid #D9B310;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
