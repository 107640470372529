#experience {
  margin: auto;
  max-width: 1120px; /*TODO : Why this difference is happening*/
  font-size: 1.2rem;
}

#experience .list-work-experience {

}

#experience .experience-show-more {
  display: flex;
  justify-content: center;
}

#experience .experience-show-more .item {
  color: #1D2731;
  border: 0.1rem solid #D9B310;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
