.login {
  margin: auto;
  max-width: 1080px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-form input {
  margin: 0.5rem;
}
