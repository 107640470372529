.description {
  font-size: 1rem;
}

.description li {
  list-style: none;
}

.description-form {
  display: flex;
  flex-direction: column;
}

.description-form input {
  margin: 0.5rem;
  min-height: 2rem;
}

.description-form textarea {
  width: auto;
  height: 20rem;
}

.admin-description-tag-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 20rem;
  padding: 0;
}

.admin-description-tag-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 2rem;
  margin: 0.1rem;
  border: 1px solid black;
}
